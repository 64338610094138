/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import FacingOptions from "./sub-components/FacingOptions";

const useStyles = makeStyles((theme) => ({
  stTitleBundler: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const FacingMain = (props) => {
  const classes = useStyles();

  const [FCSelected, setFCSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateFC === "function"
      ? props.updateFC(FCSelected)
      : undefined;
  }, [FCSelected]);

  return (
    <React.Fragment>
      <div className={classes.stTitleBundler}>
        <FacingOptions
          oc={props.oc1}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateFC={(sel) => {
            setFCSelected(sel);
          }}
        />
      </div>
    </React.Fragment>
  );
};
export default FacingMain;
