/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

// import SpecialTagsMain from "./SpecialTags/SpecialTagsMain";
import FacingMain from "./Facing/FacingMain";
import FloorsMain from "./Floors/FloorsMain";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    margin: "1rem 0rem 0rem 0rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
}));

const SlideMain = (props) => {
  const classes = useStyles();
  const [floorsMin] = React.useState(0);
  const [floorsMax] = React.useState(0);
  const [FCSelected, setFCSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.floorsFs === "function"
      ? props.floorsFs(floorsMin, floorsMax)
      : undefined;
  }, [floorsMin, floorsMax]);

  React.useEffect(() => {
    typeof props.updateFC === "function"
      ? props.updateFC(FCSelected)
      : undefined;
  }, [FCSelected]);

  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <FloorsMain
          oc={props.oc1}
          reset={props.reset}
          fs={props.floorsFs}
          pt={props.PTSelected}
        />
        <FacingMain
          oc1={props.oc}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateFC={(sel) => {
            setFCSelected(sel);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default SlideMain;
