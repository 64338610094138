/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";

/* Component Imports */

import { InputBase, Button } from "@material-ui/core";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import SearchResultBar from "./sub-components/SearchResultBar";
import ShowHideFilter from "./ShowHideFilter";
import ASWrapper from "../AdvancedSearch/ASWrapper";

/* Data Imports */

import AppConfig from "../../../AppConfig";
import AreaDropDown from "../AdvancedSearch/DropdownButtons/AreaDropDown";
import AreaDropDownMobile from "../AdvancedSearch/DropdownButtons/AreaDropDownMobile";
import FilterOptions from "../AdvancedSearch/DropdownButtons/filterOptions";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  searchbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "2.625rem",
    margin: "0.25rem 0rem 0.25rem 0rem",
    borderRadius: "5px 0px 0px 5px",
    boxShadow:
      "0px 0px 10px rgb(0 0 0 / 10%), 0px 2px 5px rgb(0 0 0 / 12%), 0px 3px 5px rgb(0 0 0 / 0%)",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
      height: "2.625rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 35)]: {
      width: "100%",
      height: "2.625rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 75)]: {
      width: "100%",
      height: "2.625rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 131)]: {
      width: "100%",
      height: "2.625rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 250)]: {
      width: "100%",
      height: "2.625rem",
    },
    /*  Tablet breakpoints  */
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "100%",
      height: "2.625rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      width: "100%",
      height: "2.625rem",
    },
    /*  Desktop breakpoints */
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      height: "2.625rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 80)]: {
      width: "100%",
      height: "2.625rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 252)]: {
      width: "100%",
      height: "2.625rem",
      margin: "0px 0px 0px 1px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 320)]: {
      width: "100%",
      height: "2.625rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      height: "2.625rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 315)]: {
      width: "100%",
      height: "2.625rem",
    },
    backgroundColor: "#FFFFFF",
  },
  searchbar: {
    width: "100%",
    height: "2.625rem",
    padding: "4px 4px 4px 12px",
    borderRadius: "0px 0px 0px 0px",
    ["& fieldset"]: {
      borderColor: "transparent",
      borderRadius: "0px 0px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px 0px 0px 4px",
      },
    },
    ["&:hover fieldset"]: {
      borderColor: "transparent",
      borderRadius: "0px 0px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        borderRadius: "4px 0px 0px 4px",
      },
    },
  },
  searchButton: {
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    height: "2.625rem !important",
    borderRadius: "0px 4px 4px 0px",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
    "&:hover": {
      cursor: "pointer",
    },
    "&:disabled": {
      background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
      height: "3.5rem",
      borderRadius: "0px 4px 4px 0px",
      boxShadow:
        "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
      cursor: "not-allowed",
    },
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs + 250)]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  hidden: {
    display: "none",
  },
  li: {
    padding: "0.5rem 1rem 0.5rem 0rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    "& > span": {
      marginLeft: "1rem",
    },
  },
  listbox: {
    width: "100%",
    zIndex: 3,
    position: "absolute",
    top: "7.5rem",
    maxHeight: "22rem",
    overflow: "auto",
    listStyle: "none",
    '& div[data-focus="true"]': {
      background: "linear-gradient(90deg, #F74134 0%, #EB1C61 100%)",
      borderRadius: "5px",
      boxShadow:
        "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
      color: "white",
      cursor: "pointer",
      "& > span": {
        fontWeight: 600,
      },
    },
    "& div:active": {
      backgroundColor: "#2977F5",
      color: "white",
    },
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      left: "0.5rem",
      width: "71%",
    },
    [theme.breakpoints.only("xs")]: {
      left: "1.5rem",
      width: "70%",
    },
    [theme.breakpoints.only("sm")]: {
      left: "3rem",
      width: "22rem",
    },
    [theme.breakpoints.only("md")]: {
      left: "4rem",
      width: "22rem",
    },
    [theme.breakpoints.only("lg")]: {
      left: "4rem",
      width: "24rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      left: "5rem",
      width: "24rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      left: "23rem",
      width: "24rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      left: "36rem",
      width: "24rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      left: "72rem",
      width: "24rem",
    },
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 250)]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      gap: "0.5rem",
    },
  },
  optionsButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 250)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      gap: "0.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 255)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      gap: "0.5rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 260)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      gap: "0rem",
    },
  },
  mobileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "75%",
    gap: "1rem",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 150)]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      gap: "1rem",
    },
  },
  button: {
    [theme.breakpoints.down(theme.breakpoints.values.xs + 200)]: {
      width: "100%",
    },
  },
  showIcon: {
    display: "none",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      display: "flex",
      width: "100%",
    },
  },
  hideIcon: {
    display: "flex",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      display: "none",
    },
  },
  filterContainer: {
    backgroundColor: "#FFFFFF",
    padding: "0rem 0rem 1rem 0rem",
    borderRadius: "4px",
  },
  normalMegaContainer: {
    width: "100%",
  },
  rootHider: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const Searchbar = (props) => {
  const classes = useStyles();
  const router = useRouter();
  // const [place, setPlace] = React.useState("bengaluru");
  /* State to verify user is not performing empty search */
  const [search, setSearch] = React.useState(false);
  /* Fetch current query string from the URL and set state */
  const [query, setQuery] = React.useState(router.query ? router.query : "");
  /* State for autocomplete terms */
  const [autocomplete, setAutocomplete] = React.useState([]);
  /* State to type a value for search */
  const [searchValue, setSearchValue] = React.useState(
    router.query ? (router.query.q ? router.query.q : "") : "",
  );
  /* Set URL query */
  React.useEffect(() => {
    fetch(AppConfig.apiUrlPrefix + "search-autocomplete-terms")
      .then((res) => res.json())
      .then((sat) => setAutocomplete(sat))
      .catch((e) => console.error(e));
    setQuery(router.query ? router.query : "");
    setSearchValue(router.query.q ? router.query.q : "");
    setCity(router.query.c ? router.query.c : "any");
    setType(router.query.t ? router.query.t : "any");
    setEmptySearch(
      router.query
        ? router.query.q
          ? router.query.q.length > 0
            ? true
            : false
          : false
        : false,
    );
    document.getElementById("use-autocomplete-demo").focus();
  }, [router.query]);

  const { getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: "use-autocomplete-demo",
      onChange: (event, value) => {
        setSearchValue(value.title);
        event.preventDefault();
      },
      options: autocomplete,
      getOptionLabel: (option) => option.title,
      clearOnBlur: false,
      inputValue: searchValue,
    });

  /* State to choose a specific city for search */
  const [city, setCity] = React.useState("any");
  /* State to choose a specific type for search */
  const [type, setType] = React.useState("any");
  /* State to check if empty search is performed */
  const [emptySearch, setEmptySearch] = React.useState(false);
  /* Variables to use for immediate state manipulation */
  /* states from aswrapper*/
  const [budget, setBudget] = React.useState({ min: 0, max: 0 });
  const [area, setArea] = React.useState({ min: 0, max: 0 });
  const [bath, setbath] = React.useState({ min: 0, max: 0 });
  const [bhk, setBhk] = React.useState({ min: 0, max: 0 });
  const [floors, setFloors] = React.useState({ min: 0, max: 0 });
  const [PTSelected, setPTSelected] = React.useState([]);
  const [STSelected, setSTSelected] = React.useState([]);
  const [FCSelected, setFCSelected] = React.useState([]);
  const [BUnitMin, setBunitMin] = React.useState("");
  const [BUnitMax, setBunitMax] = React.useState("");
  const [aUnit, setAunit] = React.useState("");
  const [budShow, setBudShow] = React.useState(false);

  const [reset, setReset] = React.useState(false);
  React.useEffect(() => {
    console.log(reset);
    console.log(budShow);
  }, [reset, budShow]);

  const city1 = city;
  const type1 = type;
  const [bt, setButton] = React.useState(false);
  const [ft, setButtonF] = React.useState(true);
  const [place, setPlace] = React.useState("bengaluru");
  const [res, setRes] = React.useState("residential");
  const [bathroomShow, setBathroomShow] = React.useState(true);
  const [BHKShow, setBHKShow] = React.useState(true);
  const [floorShow, setFloorShow] = React.useState(true);

  const ApplyFilter = (opts) => {
    window.history.replaceState("", "", "/properties");
    location.search +=
      "&c=" +
      (place || city) +
      "&t=" +
      (res || type) +
      "&q=" +
      searchValue +
      "&e=" +
      emptySearch +
      (budget.min == 0 || reset === true ? "" : "&valueMin=" + budget.min) +
      (budget.max == 0 || reset === true ? "" : "&valueMax=" + budget.max) +
      (BUnitMin == 0 || reset === true ? "" : "&budgetUnitMin=" + BUnitMin) +
      (BUnitMax == 0 || reset === true ? "" : "&budgetUnitMax=" + BUnitMax) +
      (bhk.min == 0 || reset === true || BHKShow == true
        ? ""
        : "&bhkMin=" + bhk.min) +
      (bhk.max == 0 || reset === true || BHKShow == true
        ? ""
        : "&bhkMax=" + bhk.max) +
      (PTSelected == 0 || reset === true
        ? ""
        : "&property=" +
          PTSelected.map((s, key) => {
            return s.name;
          })) +
      (floorShow == true ? "" : "&floorsMin=" + floors.min) +
      (floors.max == 0 || reset === true || floorShow == true
        ? ""
        : "&floorsMax=" + floors.max) +
      (area.min === 0 || reset === true ? "" : "&areaMin=" + area.min) +
      (area.max === 0 || reset === true ? "" : "&areaMax=" + area.max) +
      (aUnit == 0 || reset === true ? "" : "&areaUnit=" + aUnit) +
      (bath.min == 0 || reset === true || bathroomShow == true
        ? ""
        : "&bathroomMin=" + bath.min) +
      (bath.max == 0 || reset === true || bathroomShow == true
        ? ""
        : "&bathroomMax=" + bath.max) +
      (STSelected == 0 || reset === true
        ? ""
        : "&specialtags=" +
          STSelected.map((s, key) => {
            return s.name;
          })) +
      (FCSelected == 0 || reset === true
        ? ""
        : "&facing=" +
          FCSelected.map((s, key) => {
            return s.name;
          }));
  };
  const handleAcClick = (event) => {};
  return (
    <React.Fragment>
      <div
        className={
          props.classNameGlobal
            ? props.classNameGlobal
            : classes.normalMegaContainer
        }
        style={props.styleGlobal ? props.styleGlobal : {}}
      >
        <form
          id="searchbarForm"
          method="GET"
          action="/properties"
          className={`${classes.form} ${
            props.classNameForm ? props.classNameForm : undefined
          }`}
          style={props.styleForm ? props.styleForm : {}}
        >
          <div className={classes.searchbarContainer}>
            <div className={classes.hideIcon}>
              <AreaDropDown
                place={(place) => {
                  setPlace(place);
                }}
              />
            </div>
            <InputBase
              id="outlined-basic"
              name="q"
              placeholder="Search for your property"
              type="search"
              variant="outlined"
              className={classes.searchbar}
              value={searchValue}
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  if (event.target.value.length > 0) {
                    ApplyFilter();
                  }
                }
              }}
              onInput={(event) => {
                setSearchValue(event.target.value);
                (query.c && query.c != "any") ||
                (query.t && query.t != "any") ||
                city1 != "any" ||
                type1 != "any" ||
                event.target.value.length > 0 ||
                (query.q && query.q.length > 0)
                  ? setSearch(false)
                  : setSearch(true);
                event.target.value.length === 0
                  ? setEmptySearch(true)
                  : setEmptySearch(false);
              }}
              onChange={(event) => {
                setSearchValue(event.target.value);
                event.preventDefault();
              }}
              {...getInputProps()}
            />
            {groupedOptions.length > 0 ? (
              <div
                onClick={handleAcClick}
                className={classes.listbox}
                {...getListboxProps()}
              >
                {groupedOptions.map((option, index) => (
                  <div
                    key={index}
                    className={classes.li}
                    {...getOptionProps({ option, index })}
                  >
                    <span>{option.title}</span>
                  </div>
                ))}
              </div>
            ) : null}
            <input
              name="e"
              className={classes.hidden}
              value={emptySearch}
              readOnly={true}
              onChange={(event) => {
                setEmptySearch(event.target.value);
              }}
            />
            <input
              name="c"
              defaultValue={city}
              className={classes.hidden}
              readOnly={true}
            />
            <input
              name="t"
              defaultValue={type}
              className={classes.hidden}
              readOnly={true}
            />
            <Button
              // type="submit"
              variant="contained"
              size="large"
              className={classes.searchButton}
              disabled={search}
              onClick={ApplyFilter}
            >
              <img
                src="/images/icons/social/search-white.svg"
                alt={AppConfig.imageAltText}
                width={24}
                height={24}
                loading="eager"
                referrerPolicy="no-referrer"
                className={classes.searchIconSizer}
              />
            </Button>
          </div>
          <div
            className={`${classes.root} ${
              props.scrollTrigger ? classes.rootHider : undefined
            }`}
          >
            <div className={classes.optionsButton}>
              <div className={classes.mobileContainer}>
                <div className={classes.showIcon}>
                  <AreaDropDownMobile
                    place={(place) => {
                      setPlace(place);
                    }}
                  />
                </div>
                <FilterOptions
                  res={(res) => {
                    setRes(res);
                  }}
                />
              </div>
              <div className={classes.button}>
                <ShowHideFilter
                  bt={() => {
                    setButton(true);
                  }}
                  ft={() => {
                    setButton(false);
                    setButtonF(true);
                    // setButtonF1(false);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
        {bt && ft ? (
          <Slide in={bt} timeout={800}>
            <div className={classes.filterContainer}>
              <Fade in={bt} timeout={800}>
                <ASWrapper
                  search={searchValue}
                  place={place}
                  res={res}
                  bathroomShow={bathroomShow}
                  BHKShow={BHKShow}
                  floorShow={floorShow}
                  ocClick={() => {
                    setReset(true);
                  }}
                  oc={() => {
                    setBathroomShow(false);
                  }}
                  oc1={() => {
                    setBHKShow(false);
                  }}
                  oc2={() => {
                    setFloorShow(false);
                  }}
                  oc3={() => {
                    setBudShow(false);
                  }}
                  aswrapper={(
                    budget,
                    area,
                    bath,
                    bhk,
                    floors,
                    PTSelected,
                    STSelected,
                    FCSelected,
                    BUnitMin,
                    BUnitMax,
                    aUnit,
                  ) => {
                    setBudget(budget);
                    setArea(area);
                    setbath(bath);
                    setBhk(bhk);
                    setFloors(floors);
                    setPTSelected(PTSelected);
                    setSTSelected(STSelected);
                    setFCSelected(FCSelected);
                    setBunitMin(BUnitMin);
                    setBunitMax(BUnitMax);
                    setAunit(aUnit);
                  }}
                />
              </Fade>
            </div>
          </Slide>
        ) : null}
        <SearchResultBar
          numResults={props.numResults ? props.numResults : 0}
          u={query}
          q={query.q}
          c={query.c}
          t={query.t}
          e={query.e}
        />
      </div>
    </React.Fragment>
  );
};
export default Searchbar;
