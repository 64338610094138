/* Common Imports */

import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Typography, TextField, Select, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRouter } from "next/router";

/* Component Imports */

import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    trackColor: "red",
    selectionColor: "red",
    padding: "0px 0px 15px 0px",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      display: "none",
    },
  },
  rootMob: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  contentHead: {
    width: "100%",
  },
  minMaxHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px 0px 5px 0px",
  },
  textFieldStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    padding: "0px 0rem 0px 0px",
    [theme.breakpoints.only("xs")]: {
      padding: "0px 1rem 0px 0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px 1rem 0px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 0rem 0px 0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 2rem 0px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 14rem 0px 0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "Black",
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    color: "red",
    height: 25,
    top: 10,
  },
  // rootMob: {
  //   color: 'red',
  //   height: 25,
  //   top: 10,
  // },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -6,
    marginLeft: -8,
    boxShadow: "#EBEBEB 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      backgroundColor: "currentColor",
      marginLeft: 6,
      marginRight: 5,
    },
    color: "red",
  },
  active: {},
  track: {
    height: 8,
  },
  mark: {
    backgroundColor: "#FFF",
  },
  rail: {
    color: "#FFF",
    opacity: 6,
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
})(Slider);

const marks = [
  {
    label: "15L",
    value: 15,
  },
  // {
  //   label: '35L',
  //   value: 35,
  // },
  {
    label: "57L",
    value: 57,
  },
  // {
  //   label: '75L',
  //   value: 75,
  // },
  {
    label: "99L",
    value: 99,
  },
  {
    label: "1.5Cr",
    value: 155,
  },
  {
    label: "2Cr",
    value: 200,
  },
  {
    label: "2.5Cr",
    value: 250,
  },
  {
    label: "3Cr",
    value: 300,
  },
  {
    label: "3.5Cr",
    value: 350,
  },
  {
    label: "4Cr+",
    value: 400,
  },
];

const marksMobile = [
  {
    label: "15L",
    value: 15,
  },
  // {
  //   label: '57L',
  //   value: 57,
  // },
  {
    label: "99L",
    value: 99,
  },
  {
    label: "2Cr",
    value: 200,
  },
  {
    label: "3Cr",
    value: 300,
  },
  {
    label: "4Cr+",
    value: 400,
  },
];

const SliderBudget = (props) => {
  const classes = useStyles();
  const router = useRouter();

  const [min, setMin] = React.useState(15);
  const [max, setMax] = React.useState(400);
  const [bUnitMin, setBunitMin] = React.useState("Lakhs");
  const [bUnitMax, setBunitMax] = React.useState("Crore");

  const handleChangeSlider = (event, value) => {
    setMin(value[0] < 15 ? value[0] * 100 : value[0]);
    setMax(value[1] < 15 ? value[1] * 100 : value[1]);
    if (value[0] >= 100) {
      setBunitMin("Crore");
    } else {
      setBunitMin("Lakhs");
    }
    if (value[1] >= 100) {
      setBunitMax("Crore");
    } else {
      setBunitMax("Lakhs");
    }
  };
  const handleChange3 = (event) => {
    setBunitMin(event.target.value);
  };
  const handleChange4 = (event) => {
    setBunitMax(event.target.value);
  };

  const handleChangeMainTextFieldMin = (event) => {
    setMin(
      event.target.value === ""
        ? Number(event.target.value)
        : Number(event.target.value)
    );
    66;
  };
  const handleChangeMainTextFieldMax = (event) => {
    setMax(
      event.target.value === ""
        ? Number(event.target.value)
        : Number(event.target.value)
    );
  };

  React.useEffect(() => {
    typeof props.cu === "function"
      ? props.cu(min, max, bUnitMin, bUnitMax)
      : undefined;
  }, [min, max, bUnitMin, bUnitMax]);

  React.useEffect(() => {
    if (props.reset === true) {
      setMin(15);
      setMax(400);
    }
  }, [props.reset]);

  React.useEffect(() => {
    setMin(
      router.query.valueMin
        ? parseFloat(router.query.valueMin) < 15
          ? parseFloat(router.query.valueMin) * 100
          : parseFloat(router.query.valueMin)
        : 15
    );
    setMax(
      router.query.valueMax
        ? parseFloat(router.query.valueMax) < 15
          ? parseFloat(router.query.valueMax) * 100
          : parseFloat(router.query.valueMax)
        : 400
    );
    setBunitMin(
      router.query.budgetUnitMin ? router.query.budgetUnitMin : "Lakhs"
    );
    setBunitMax(
      router.query.budgetUnitMax ? router.query.budgetUnitMax : "Crore"
    );
  }, [router.query]);

  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <div className={classes.minMaxHead}>
          <div>
            <Typography>Min</Typography>
          </div>
          <div>
            <Typography>Max</Typography>
          </div>
        </div>
        <div className={classes.root}>
          <CustomSlider
            onTouchEnd={props.oc}
            onClick={props.oc}
            marks={marks}
            step={0.5}
            min={15}
            max={400}
            value={[min, max]}
            onChange={handleChangeSlider}
            // valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        </div>
        <div className={classes.rootMob}>
          <CustomSlider
            onTouchEnd={props.oc}
            onClick={props.oc}
            marks={marksMobile}
            step={0.5}
            min={15}
            max={400}
            value={[min, max]}
            onChange={handleChangeSlider}
            // valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        </div>
        <div className={classes.textFieldStyle}>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMin}
              value={min < 100 ? min : min / 100}
              placeholder="Minimum"
            />
          </div>
          <div>
            <Select
              color="secondary"
              variant="standard"
              value={bUnitMin}
              onChange={handleChange3}
              IconComponent={ExpandMoreIcon}
              defaultValue={bUnitMin}
            >
              <MenuItem value="Thousand">Thousand</MenuItem>
              <MenuItem value="Lakhs">Lakhs</MenuItem>
              <MenuItem value="Crore">Crore</MenuItem>
            </Select>
          </div>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMax}
              value={max < 100 ? max : max / 100}
              placeholder="Maximum"
            />
          </div>
          <div>
            <Select
              color="secondary"
              variant="standard"
              value={bUnitMax}
              onChange={handleChange4}
              IconComponent={ExpandMoreIcon}
              defaultValue={bUnitMax}
            >
              <MenuItem value="Thousand">Thousand</MenuItem>
              <MenuItem value="Lakhs">Lakhs</MenuItem>
              <MenuItem value="Crore">Crore</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SliderBudget;
