import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Paper, Select, MenuItem } from '@material-ui/core';
import { useRouter } from 'next/router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    background: '#fffff',
    '&:focus': {
      background: 'transparent',
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  bscContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 10px 0px 0px',
    width: '9.2rem',
    height: '2.625rem',
    borderRadius: '4px',
    border: '3px solid transparent',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      width: '100%',
      margin: '0px 0px 0px 0px',
    },
  },
  paperContainer: {
    width: '100%',
    padding: '0px 0px 0px 10px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 0px 0px 10px',
    },
    // [theme.breakpoints.down('sm')]: {
    //   display: 'flex',
    //   width: '7rem',
    // },
  },
  sizeContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.xs + 260)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'right',
      width: '100%',
    },
  },
}));

const FilterOptions = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [area, setArea] = React.useState('residential');
  React.useEffect(() => {
    typeof props.res === 'function' ? props.res(area) : setArea(area);
  }, [area]);
  React.useEffect(() => {
    setArea(router.query.t ? router.query.t : 'residential');
  }, [router.query]);
  const handleChange = (event) => {
    setArea(event.target.value);
  };
  return (
    <div className={classes.sizeContainer}>
      <Paper className={classes.bscContainer}>
        <Select
          value={area}
          onChange={handleChange}
          input={<BootstrapInput />}
          IconComponent={ExpandMoreIcon}
          className={classes.paperContainer}
          defaultValue={area}
        >
          <MenuItem onChange={handleChange} value="any">
            Any
          </MenuItem>
          <MenuItem onChange={handleChange} value="residential">
            Residential
          </MenuItem>
          <MenuItem onChange={handleChange} value="commercial">
            Commercial
          </MenuItem>
          <MenuItem onChange={handleChange} value="agricultural">
            Agricultural
          </MenuItem>
          <MenuItem onChange={handleChange} value="industrial">
            Industrial
          </MenuItem>
          <MenuItem onChange={handleChange} value="warehousing">
            Warehousing
          </MenuItem>
        </Select>
      </Paper>
    </div>
  );
};
export default FilterOptions;
