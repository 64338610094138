/* Common Imports */

import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Typography, TextField, Select, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useRouter } from 'next/router';
/* Component Imports */

import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    trackColor: 'red',
    selectionColor: 'red',
    padding: '0px 10px 15px 10px',
  },
  contentHead: {
    width: '100%',
  },
  minMaxHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0px 0px 5px 0px',
  },
  textFieldStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    padding: '0px 0rem 0px 0px',
    [theme.breakpoints.only('xs')]: {
      padding: '0px 1rem 0px 0px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '0px 1rem 0px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '0px 0rem 0px 0px',
    },
    [theme.breakpoints.only('md')]: {
      padding: '0px 2rem 0px 0px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '0px 14rem 0px 0px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'Black',
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    color: 'red',
    height: 25,
    top: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -6,
    marginLeft: -8,
    boxShadow: '#EBEBEB 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      backgroundColor: 'currentColor',
      marginLeft: 6,
      marginRight: 5,
    },
    color: 'red',
  },
  active: {},
  track: {
    height: 8,
  },
  mark: {
    backgroundColor: '#FFF',
  },
  rail: {
    color: '#FFF',
    opacity: 6,
    borderRadius: '4px',
    border: '3px solid transparent',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
  },
})(Slider);

const marks = [
  {
    label: '400',
    value: 400,
  },
  {
    label: '2200',
    value: 2200,
  },
  {
    label: '4100',
    value: 4100,
  },
  {
    label: '6000+',
    value: 6000,
  },
];

const SliderArea = (props) => {
  const classes = useStyles();
  const [areaUnit, setAreaUnit] = React.useState('sqft');
  const [min, setMin] = React.useState(2200);
  const [max, setMax] = React.useState(4100);
  const router = useRouter();

  const handleChange3 = (event) => {
    setAreaUnit(event.target.value);
  };
  const handleChangeSlider = (event, value) => {
    setMin(value[0]);
    setMax(value[1]);
  };
  const handleChangeMainTextFieldMin = (event) => {
    setMin(
      event.target.value === ''
        ? Number(event.target.value)
        : Number(event.target.value)
    );
  };
  const handleChangeMainTextFieldMax = (event) => {
    setMax(
      event.target.value === ''
        ? Number(event.target.value)
        : Number(event.target.value)
    );
  };

  React.useEffect(() => {
    typeof props.au === 'function' ? props.au(min, max, areaUnit) : undefined;
  }, [min, max, areaUnit]);
  // React.useEffect(() => {}, [min, max]);

  React.useEffect(() => {
    if (props.reset1 === true) {
      setMin(2200);
      setMax(4100);
    }
  }, [props.reset]);

  React.useEffect(() => {
    setMin(router.query.areaMin ? parseInt(router.query.areaMin) : 2200);
    setMax(router.query.areaMax ? parseInt(router.query.areaMax) : 4100);
    setAreaUnit(router.query.areaUnit ? router.query.areaUnit : 'sqft');
  }, [router.query]);

  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <div className={classes.minMaxHead}>
          <div>
            <Typography>Min</Typography>
          </div>
          <div>
            <Typography>Max</Typography>
          </div>
        </div>
        <div className={classes.root}>
          <CustomSlider
            onTouchEnd={props.oc}
            onClick={props.oc}
            marks={marks}
            step={1}
            min={400}
            max={6000}
            value={[min, max]}
            onChange={handleChangeSlider}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        </div>
        <div className={classes.textFieldStyle}>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMin}
              value={min}
              placeholder="Minimum"
            />
          </div>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMax}
              value={max}
              placeholder="Maximum"
            />
          </div>
          <div>
            <Select
              color="secondary"
              variant="standard"
              value={areaUnit}
              onChange={handleChange3}
              IconComponent={ExpandMoreIcon}
              defaultValue={areaUnit}
            >
              <MenuItem value="sqft">Sqft</MenuItem>
            </Select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SliderArea;
