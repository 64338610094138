import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Button, Typography } from "@material-ui/core";

/* Data Imports */

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'right',
    borderRadius: '4px',
    fontWeight: 400,
    border: '3px solid transparent',
    width: '6.813rem',
    height: '2.625rem',
    color: '#FFFFFF',
    margin: '0px 0px 0px 25px',
    background: '-webkit-linear-gradient(0deg, #F74134, #EB1C61)',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '4px 4px 4px 4px',
      width: '100%',
      margin: '0px 0px 0px 0px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 0px 0px 0px',
    },
  },
  secondaryButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '0.875rem',
    margin: '0px 0px 0px 0px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  nofilterContainer: {
    display: 'none',
  },
}));

const ShowHideFilter = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <React.Fragment>
      {open ? (
        <Button
          rel="noopener"
          className={classes.primaryButton}
          onClick={() => {
            typeof props.bt === "function" ? props.bt() : null;
            setOpen(false);
          }}
        >
          Show Filter
        </Button>
      ) : (
        <Button
          rel="noopener"
          className={classes.primaryButton}
          onClick={() => {
            typeof props.ft === "function" ? props.ft() : null;
            setOpen(true);
          }}
        >
          <Typography className={classes.secondaryButton}>
            Hide Filter
          </Typography>
        </Button>
      )}
    </React.Fragment>
  );
};
export default ShowHideFilter;
