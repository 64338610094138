import React from "react";
import { useRouter } from "next/router";
/* Component Imports */

import { Typography } from "@material-ui/core";

/* Data Imports */

import AppConfig from "../../../../../AppConfig";
import useStyles from "../../checkBoxStyle";

const SpecialTagsCheckboxGroup = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [selected, setSelected] = React.useState(
    (props.options ? props.options : options).map((bsc, key) => {
      return { checked: classes.bscContainer };
    }),
  );

  React.useEffect(() => {
    let specialtags = router.query.specialtags;
    if (specialtags) {
      specialtags = specialtags.split(",");
      const c = [];
      for (let i = 0; i < props.options.length; i++) {
        specialtags.includes(props.options[i].name)
          ? props.options.map((sel, key) => {
              if (key === i) {
                c.push(i);
              }
            })
          : undefined;
      }
      updateCheckedStateArray(null, c);
    }
  }, [router.query]);

  const updateCheckedState = (event, keyGiven) => {
    setSelected(
      (props.options ? props.options : options).map((bsc, keyCurrent) => {
        return {
          checked:
            keyCurrent == keyGiven
              ? selected[keyCurrent].checked === classes.bscContainer
                ? classes.bscContainerSelected
                : classes.bscContainer
              : props.multiple
              ? selected[keyCurrent].checked
              : classes.bscContainer,
        };
      }),
    );
  };
  const updateCheckedStateArray = (event, keyGivenArray) => {
    setSelected(
      (props.options ? props.options : options).map((bsc, keyCurrent) => {
        return {
          checked: keyGivenArray.includes(keyCurrent)
            ? selected[keyCurrent].checked === classes.bscContainer
              ? classes.bscContainerSelected
              : classes.bscContainer
            : props.multiple
            ? selected[keyCurrent].checked
            : classes.bscContainer,
        };
      }),
    );
  };
  React.useEffect(() => {
    typeof props.updateST === "function"
      ? props.updateST(
          selected
            .map((sel, key) => {
              return sel.checked.includes(classes.bscContainerSelected)
                ? props.options[key]
                : null;
            })
            .filter((i) => i !== null),
        )
      : undefined;
  }, [selected]);

  React.useEffect(() => {
    for (let i = 0; i < props.options.length; i++) {
      if (props.options[i].text === props.nm) {
        updateCheckedState(null, i);
      }
    }
  }, [props.nm]);

  React.useEffect(() => {
    if (props.reset === true) {
      setSelected(
        (props.options ? props.options : options).map((bsc, key) => {
          return { checked: classes.bscContainer };
        }),
      );
    }
  }, [props.reset]);

  return (
    <React.Fragment>
      {(props.options ? props.options : options).map((bsc, key) => {
        return (
          <div
            className={selected[key].checked}
            onClick={(event) => {
              updateCheckedState(event, key);
              typeof props.oc === "function" ? props.oc(event, key) : undefined;
            }}
            key={key}
          >
            <div className={classes.bscContentContainer}>
              <input
                style={{ display: "none" }}
                name={bsc.name}
                type="checkbox"
                value={
                  selected[key].checked === classes.bscContainerSelected
                    ? true
                    : false
                }
                checked={
                  selected[key].checked === classes.bscContainerSelected
                    ? true
                    : false
                }
                onChange={() => {}}
              />
              <img
                src={bsc.icon ? bsc.icon : "/images/icons/property/nri.svg"}
                alt={AppConfig.imageAltText}
                width={512}
                height={512}
                loading="lazy"
                referrerPolicy="no-referrer"
                className={classes.iconSizer}
              />
              <Typography
                variant="h1"
                component="span"
                className={classes.text}
              >
                {bsc.text}
              </Typography>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default SpecialTagsCheckboxGroup;
