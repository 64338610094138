/* Common Imports */

import React from "react";
import { useRouter } from "next/router";

/* Component Imports */

import AdvancedSrhSectionOne from "./AdvancedSrhSectionOne";
import AdvancedSrhSectionTwo from "./AdvancedSrhSectionTwo";
import AdvancedSrhSectionThree from "./AdvancedSrhSectionThree";
import AdvancedSrhSectionFour from "./AdvancedSrhSectionFour";
import SelectedFilter from "./SelectedFilter";

/* Data Imports */

import options from "./PropertyType/data/Options";

const ASWrapper = (props) => {
  const router = useRouter();
  const [budget, setBudget] = React.useState({ min: 0, max: 0 });
  const [area, setArea] = React.useState({ min: 0, max: 0 });
  const [bath, setbath] = React.useState({ min: 0, max: 0 });
  const [bhk, setBhk] = React.useState({ min: 0, max: 0 });
  const [floors, setFloors] = React.useState({ min: 0, max: 0 });
  const [PTSelected, setPTSelected] = React.useState([]);
  const [STSelected, setSTSelected] = React.useState([]);
  const [FCSelected, setFCSelected] = React.useState([]);
  const [BUnitMin, setBunitMin] = React.useState("lakhs");
  const [BUnitMax, setBunitMax] = React.useState("crores");
  const [aUnit, setAunit] = React.useState("sqft");
  const [chip, setChip] = React.useState([]);
  const [nm, setNm] = React.useState("");
  const [reset, setReset] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [dele, setDelete] = React.useState(false);
  // const [bathroomShow, setBathroomShow] = React.useState(true);
  // const [BHKShow, setBHKShow] = React.useState(true);
  React.useEffect(() => {
    console.log(show);
  }, [show]);
  React.useEffect(
    () => {
      typeof props.aswrapper === "function"
        ? props.aswrapper(
            budget,
            area,
            bath,
            bhk,
            floors,
            PTSelected,
            STSelected,
            FCSelected,
            BUnitMin,
            BUnitMax,
            aUnit,
            chip,
            nm,
            reset,
            dele
          )
        : null;
    },
    [
      budget,
      area,
      bath,
      bhk,
      floors,
      PTSelected,
      STSelected,
      FCSelected,
      BUnitMin,
      BUnitMax,
      aUnit,
      chip,
      nm,
      reset,
      dele,
    ],
    typeof props.dele === "function" ? props.dele(reset) : null
  );
  React.useEffect(() => {
    typeof props.bt === "function" ? props.bt() : null;
  }, [props.bt]);
  React.useEffect(() => {
    Object.keys(router.query).length === 0 ? setReset(true) : setReset(false);
  }, [router.query]);
  return (
    <React.Fragment>
      <div
        style={reset ? { width: "100%", height: "3rem" } : { display: "none" }}
      ></div>
      {!reset ? (
        <SelectedFilter
          show={show}
          bhkShow={props.BHKShow}
          floorShow={props.floorShow}
          bathroomShow={props.bathroomShow}
          ocClick={props.ocClick}
          search={props.search}
          options={options}
          res={props.res}
          place={props.place}
          reset={reset}
          valueMax={budget.max}
          valueMin={budget.min}
          areaMin={area.min}
          areaMax={area.max}
          bhkMin={bhk.min}
          bhkMax={bhk.max}
          floorMin={floors.min}
          floorMax={floors.max}
          bathMin={bath.min}
          bathMax={bath.max}
          PTSelected={PTSelected}
          clearFilter={(newData) => {
            setBudget({ min: newData, max: newData });
            setBunitMin(newData);
            setBunitMax(newData);
            setBhk({ min: newData, max: newData });
            setPTSelected(newData);
            setFloors({ min: newData, max: newData });
            setArea({ min: newData, max: newData });
            setAunit(newData);
            setbath({ min: newData, max: newData });
            setSTSelected(newData);
            setFCSelected(newData);
            setReset(true);
            setDelete(true);
          }}
          STSelected={STSelected}
          FCSelected={FCSelected}
          budgetUnitMin={BUnitMin}
          budgetUnitMax={BUnitMax}
          areaUnit={aUnit}
          apClick={() => {
            setShow(true);
          }}
          chip={(cd, nm) => {
            setChip(cd);
            setNm(nm);
          }}
        />
      ) : null}
      {/* Budget & Area */}
      <AdvancedSrhSectionOne
        oc1={() => {
          setReset(false);
        }}
        oc={() => {
          setReset(false);
        }}
        PTSelected={PTSelected}
        reset={reset}
        nm={nm}
        budgetSu={(bMin, bMax, unitMin, unitMax) => {
          setBudget({ min: bMin, max: bMax });
          setBunitMin(unitMin);
          setBunitMax(unitMax);
          // setReset(false);
        }}
        areaAu={(aMin, aMax, aUnit) => {
          setArea({ min: aMin, max: aMax });
          setAunit(aUnit);
          // setReset(false);
        }}
      />

      {/* Property Type & Special Tags*/}
      <AdvancedSrhSectionTwo
        oc={() => {
          setReset(false);
        }}
        oc2={() => {
          setReset(false);
          setShow(true);
        }}
        reset={reset}
        chip={chip}
        nm={nm}
        PTSelected={PTSelected}
        updateCB={(sel) => {
          setPTSelected(sel);
          // setReset(false);
        }}
        updateST={(sel) => {
          setSTSelected(sel);
          // setReset(false);
        }}
      />
      {/* BHK & Bathrooms */}
      <AdvancedSrhSectionThree
        oc={props.oc}
        oc1={props.oc1}
        PTSelected={PTSelected}
        reset={reset}
        bhkCu={(bhkMin, bhkMax) => {
          setBhk({ min: bhkMin, max: bhkMax });
          // setReset(false);
        }}
        bathBu={(bathMin, bathMax) => {
          setbath({ min: bathMin, max: bathMax });
          // setReset(false);
        }}
      />

      {/* Floor & Facing */}
      <AdvancedSrhSectionFour
        oc1={props.oc2}
        PTSelected={PTSelected}
        reset={reset}
        chip={chip}
        nm={nm}
        oc={() => {
          setReset(false);
        }}
        updateFC={(sel) => {
          setFCSelected(sel);
          // setReset(false);
        }}
        floorsFs={(floorsMin, floorsMax) => {
          setFloors({ min: floorsMin, max: floorsMax });
          // setReset(false);
        }}
      />
    </React.Fragment>
  );
};

export default ASWrapper;
