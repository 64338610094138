/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Data Imports */

import FacingCheckboxGroup from "./FacingCheckboxGroup";
import options from "../data/FOptions";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    padding: "0px 0px 0px 10px",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "0.5rem",
    [theme.breakpoints.up("lg")]: {
      margin: "0px 0px 0px 20px",
      gap: "1rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [theme.breakpoints.up("xl")]: {
      margin: "0px 0px 0px 20px",
      gap: "2rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [theme.breakpoints.only(theme.breakpoints.values.xs + 280)]: {
      margin: "5px 0px 0px 20px",
      width: "80%",
    },
  },
  budgetHead: {
    width: "100%",
  },
  budgetSummary: {
    backgroundColor: "#FAFAFA",
  },
  gg: {
    margin: "0px 0px 0px 10px",
    fontSize: "1rem",
    height: "1.625rem",
    display: "flex",
    fontWeight: 400,
    width: "8.813rem",
    fontSize: "0.75rem",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    border: "double 2px transparent",
    backgroundImage:
      "linear-gradient(white, white), radial-gradient(circle at top left, #F74134, #EB1C61)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
  nogg: {
    display: "none",
  },
}));

const FacingOptions = (props) => {
  const classes = useStyles();

  const [FCSelected, setFCSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateFC === "function"
      ? props.updateFC(FCSelected)
      : undefined;
  }, [FCSelected]);

  // React.useEffect(() => {
  //   setFCSelected(router.query.FCSelected ? router.query.FCSelected : []);
  // }, [router.query]);

  return (
    <React.Fragment>
      <Accordion className={classes.budgetHead}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.budgetSummary}
        >
          <Typography
            variant="h1"
            component="span"
            className={classes.sectionTitle}
          >
            Facing{" "}
            <Paper
              className={FCSelected.length > 0 ? classes.gg : classes.nogg}
            >
              {FCSelected.length + "/" + "4"} Filters Selected
            </Paper>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.boxContainer}>
            <FacingCheckboxGroup
              oc={props.oc}
              reset={props.reset}
              chip={props.chip}
              nm={props.nm}
              multiple={true}
              options={options}
              updateFC={(selected) => {
                setFCSelected(selected);
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default FacingOptions;
