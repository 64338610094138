/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

// import AreaMain from "./Area/AreaMain";
// import BathMain from "./Bathroom/BathMain";
import BHKMain from "./BHK/BHKMain";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    margin: "1rem 0rem 1rem 0rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
}));

const BtnMain = (props) => {
  const classes = useStyles();

  const [bhkMin] = React.useState(0);
  const [bhkMax] = React.useState(0);
  const [bathMin] = React.useState(0);
  const [bathMax] = React.useState(0);

  React.useEffect(() => {
    typeof props.bhkCu === "function" ? props.bhkCu(bhkMin, bhkMax) : undefined;
  }, [bhkMin, bhkMax]);

  React.useEffect(() => {
    typeof props.bathBu === "function"
      ? props.bathBu(bathMin, bathMax)
      : undefined;
  }, [bathMin, bathMax]);

  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <BHKMain
          oc={props.oc1}
          reset={props.reset}
          cu={props.bhkCu}
          pt={props.PTSelected}
        />
        {/* <BathMain
          oc={props.oc}
          reset={props.reset}
          bu={props.bathBu}
          pt={props.PTSelected}
        /> */}
      </div>
    </React.Fragment>
  );
};

export default BtnMain;
