const options = [
  {
    icon: "/images/icons/search/north.svg",
    text: "North",
    name: "north",
  },
  {
    icon: "/images/icons/search/south.svg",
    text: "South",
    name: "south",
  },
  {
    icon: "/images/icons/search/east.svg",
    text: "East",
    name: "east",
  },
  {
    icon: "/images/icons/search/west.svg",
    text: "West",
    name: "west",
  },
  {
    icon: "/images/icons/search/North-East.svg",
    text: "North-East",
    name: "north east",
  },
  {
    icon: "/images/icons/search/North-West.svg",
    text: "North-West",
    name: "north west",
  },
  {
    icon: "/images/icons/search/South-East.svg",
    text: "South-East",
    name: "south east",
  },
  {
    icon: "/images/icons/search/South-West.svg",
    text: "South-West",
    name: "south west",
  },
];
export default options;
