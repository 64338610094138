/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  searchResultBarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // width: "100%",
  },
  searchResultTitle: {
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
  },
}));

const SearchResultBar = (props) => {
  const classes = useStyles();

  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    if (props.c && props.t && props.e) {
      if (props.e == "true") {
        if (props.c != "any" && props.t != "any") {
          setMessage(
            "Here's all the " +
              props.t +
              " properties available in " +
              (props.c[0].toUpperCase() + props.c.slice(1)) +
              "!"
          );
        } else if (props.c != "any" && props.t == "any") {
          setMessage(
            "Here's all the properties available in " +
              (props.c[0].toUpperCase() + props.c.slice(1)) +
              "!"
          );
        } else if (props.c == "any" && props.t != "any") {
          setMessage("Here's all the " + props.t + " properties we have!");
        } else {
          setMessage("");
        }
      } else {
        if (props.c != "any" && props.t != "any") {
          setMessage(
            "Found " +
              props.numResults +
              " " +
              props.t +
              " properties in " +
              (props.c[0].toUpperCase() + props.c.slice(1)) +
              ' that match the term "' +
              props.q +
              '"'
          );
        } else if (props.c != "any" && props.t == "any") {
          setMessage(
            "Found " +
              props.numResults +
              " properties in " +
              (props.c[0].toUpperCase() + props.c.slice(1)) +
              ' that match the term "' +
              props.q +
              '"'
          );
        } else if (props.c == "any" && props.t != "any") {
          setMessage(
            "Found " +
              props.numResults +
              " " +
              props.t +
              ' properties that match the term "' +
              props.q +
              '"'
          );
        } else {
          setMessage(
            "Found " +
              props.numResults +
              ' properties that match the term "' +
              props.q +
              '"'
          );
        }
      }
    }
  }, [props.u, props.numResults]);

  return (
    <React.Fragment>
      <div className={classes.searchResultBarContainer}>
        <div className={classes.searchResultTitleContainer}>
          <Typography
            variant="body2"
            component="span"
            className={classes.searchResultTitle}
          >
            {message}
          </Typography>
        </div>
        <div className={classes.searchResultSortOptionContainer}></div>
      </div>
    </React.Fragment>
  );
};

export default SearchResultBar;
