const Options = [
  {
    icon: "/images/icons/property/apartment.svg",
    text: "Apartment",
    name: "apartment",
  },
  {
    icon: "/images/icons/property/houses.svg",
    text: "House",
    name: "house",
  },
  {
    icon: "/images/icons/property/plot.svg",
    text: "Plot",
    name: "plot",
  },
  {
    icon: "/images/icons/property/land.svg",
    text: "Land",
    name: "land",
  },
  {
    icon: "/images/icons/property/factories.svg",
    text: "Factories",
    name: "factories",
  },
  {
    icon: "/images/icons/property/mines.svg",
    text: "Mines",
    name: "mines",
  },
  {
    icon: "/images/icons/property/warehouse.svg",
    text: "Warehouse",
    name: "warehouse",
  },
];
export default Options;
