/* Common Imports */
import React from 'react';
import SliderArea from './sub-components/SliderArea';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  budgetHead: {
    width: '100%',
  },
  sectionHead: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    padding: '0px 0px 0px 10px',
  },
  budgetSummary: {
    backgroundColor: '#fafafa',
  },
}));

const AreaMain = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.customContainer}>
      <Accordion className={classes.budgetHead}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.budgetSummary}
        >
          <Typography className={classes.sectionHead}>Area</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SliderArea oc={props.oc1} au={props.au} reset={props.reset1} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AreaMain;
