import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  bscContainer: {
    cursor: "pointer",
    /*  Mobile breakpoints  */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 35)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 75)]: {
      width: "8rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 131)]: {
      width: "9.5rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 250)]: {
      width: "8rem",
      height: "2.446rem",
    },
    /*  Tablet breakpoints  */
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "8.7rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      width: "11.9rem",
      height: "2.446rem",
    },
    /*  Desktop breakpoints */
    [theme.breakpoints.up("lg")]: {
      width: "9.558rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 80)]: {
      width: "10rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 252)]: {
      width: "8.5rem",
      height: "2.446rem",
      margin: "0px 0px 0px 1px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 320)]: {
      width: "9.3rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "9.3rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 315)]: {
      width: "11rem",
      height: "2.446rem",
    },
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
  bscContainerSelected: {
    cursor: "pointer",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 35)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 75)]: {
      width: "8rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 131)]: {
      width: "9.5rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 250)]: {
      width: "8rem",
      height: "2.446rem",
    },
    /*  Tablet breakpoints  */
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "8.7rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      width: "11.9rem",
      height: "2.446rem",
    },
    /*  Desktop breakpoints */
    [theme.breakpoints.up("lg")]: {
      width: "9.558rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 80)]: {
      width: "10rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 252)]: {
      width: "8.5rem",
      height: "2.446rem",
      margin: "0px 0px 0px 1px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 320)]: {
      width: "9.3rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "9.3rem",
      height: "2.446rem",
      margin: "0px 0px 0px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 315)]: {
      width: "11rem",
      height: "2.446rem",
    },
    borderRadius: "4px",
    border: "double 3px transparent",
    backgroundImage:
      "linear-gradient(white, white), radial-gradient(circle at top left, #F74134, #EB1C61)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
  bscContentContainer: {
    widht: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.5rem",
    padding: "0.5rem 0rem 0.5rem 0.2rem",
  },
  text: {
    wordWrap: "break-word",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      textAlign: "center",
    },
    [theme.breakpoints.only("xs")]: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
      textAlign: "center",
    },
    [theme.breakpoints.only("sm")]: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.3125rem",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      fontWeight: 600,
      fontSize: "0.9rem",
      lineHeight: "1.6875rem",
      textAlign: "center",
    },
  },
  iconSizer: {
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "0.5em",
      height: "0.5rem",
      // margin: "0rem 0rem 0.25rem 0rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "1.5em",
      height: "1.5rem",
      margin: "0rem 0rem 0rem 0rem",
    },
    [theme.breakpoints.only("sm")]: {
      width: "1.75rem",
      height: "1.75rem",
      margin: "0rem 0rem 0rem 0rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "1.775rem",
      height: "1.775rem",
      margin: "0rem 0rem 0rem 0rem",
    },
  },
}));
export default useStyles;
