/* Common Imports */
import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import SliderBudget from "./sub-components/SliderBudget";

const useStyles = makeStyles((theme) => ({
  customContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: '100%',
    },
  },
  budgetHead: {
    width: '100%',
  },
  sectionHead: {
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    padding: '0px 0px 0px 10px',
  },
  budgetSummary: {
    backgroundColor: '#fafafa',
  },
}));

const BudgetMain = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.customContainer}>
      <Accordion className={classes.budgetHead}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.budgetSummary}
        >
          <Typography className={classes.sectionHead}>Budget</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SliderBudget oc={props.oc} reset={props.reset} cu={props.cu} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default BudgetMain;
