import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Select, MenuItem, InputBase } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRouter } from "next/router";
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    background: "#fffff",
    "&:focus": {
      background: "transparent",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  bscContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "4rem",
    margin: "0px 0px 0px 25px",
    width: "11rem",
    height: "2.625rem",
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0px 0px 0px 0px",
    },
  },
  paperContainer: {
    width: "100%",
    padding: "0px 0px 0px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    // [theme.breakpoints.down('sm')]: {
    //   display: 'flex',
    //   width: '7rem',
    // },
  },
  sizeContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
    },
  },
}));
const AreaDropDownMobile = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [place, setPlace] = React.useState("any");
  const handleChange = (event) => {
    setPlace(event.target.value);
  };
  React.useEffect(() => {
    typeof props.place === "function"
      ? props.place(place)
      : setPlace(props.place);
  }, [place]);
  React.useEffect(() => {
    setPlace(router.query.c ? router.query.c : "any");
  }, [router.query]);
  return (
    <div className={classes.sizeContainer}>
      <Paper elevation={1} className={classes.bscContainer}>
        <Select
          value={place}
          variant="outlined"
          label="Age"
          onChange={handleChange}
          input={<BootstrapInput />}
          IconComponent={ExpandMoreIcon}
          className={classes.paperContainer}
          defaultValue={place}
        >
          <MenuItem value="any">Any City</MenuItem>
          <MenuItem value="bengaluru">Bengaluru</MenuItem>
          <MenuItem value="mysore">Mysore</MenuItem>
        </Select>
      </Paper>
    </div>
  );
};
export default AreaDropDownMobile;
