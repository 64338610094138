/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { useRouter } from "next/router";

/* Component Imports */

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

/* Data Imports */

import SpecialTagsCheckboxGroup from "./SpecialTagsCheckboxGroup";
import options from "../data/STOptions";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontWeight: 500,
    fontSize: '1.5rem',
    lineHeight: '2.25rem',
    padding: '0px 0px 0px 10px',
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '1rem',
    [theme.breakpoints.up('lg')]: {
      margin: '0px 0px 0px 20px',
      gap: '1rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    [theme.breakpoints.up('xl')]: {
      margin: '0px 0px 0px 20px',
      gap: '2rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  budgetHead: {
    width: '100%',
  },
  budgetSummary: {
    backgroundColor: '#fafafa',
  },
  gg: {
    margin: '0px 0px 0px 10px',
    fontSize: '1rem',
    height: '1.625rem',
    display: 'flex',
    fontWeight: 400,
    width: '8.813rem',
    fontSize: '0.75rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',

    border: 'double 2px transparent',
    backgroundImage:
      'linear-gradient(white, white), radial-gradient(circle at top left, #F74134, #EB1C61)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
  },
  nogg: {
    margin: '0px 0px 0px 10px',
    fontSize: '1rem',
    height: '1.625rem',
    display: 'none',
    fontWeight: 400,
    width: '8.813rem',
    fontSize: '0.75rem',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',

    border: 'double 2px transparent',
    backgroundImage:
      'linear-gradient(white, white), radial-gradient(circle at top left, #F74134, #EB1C61)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow:
      '0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)',
  },
}));

const SpecialTagsOptions = (props) => {
  const classes = useStyles();
  // const router = useRouter();

  const [STSelected, setSTSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateST === "function"
      ? props.updateST(STSelected)
      : undefined;
  }, [STSelected]);

  // React.useEffect(() => {
  //   setSTSelected(router.query.STSelected ? router.query.STSelected : []);
  // }, [router.query]);

  return (
    <React.Fragment>
      <Accordion className={classes.budgetHead}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.budgetSummary}
          onClick={() => {
            props.updateST(STSelected);
          }}
        >
          <Typography
            variant="h1"
            component="span"
            className={classes.sectionTitle}
          >
            Special Tags{}
            <Paper
              className={STSelected.length > 0 ? classes.gg : classes.nogg}
            >
              {STSelected.length + "/" + "5"} Filters Selected
            </Paper>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.boxContainer}>
            <SpecialTagsCheckboxGroup
              oc={props.oc}
              reset={props.reset}
              chip={props.chip}
              nm={props.nm}
              multiple={true}
              options={options}
              updateST={(selected) => {
                setSTSelected(selected);
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default SpecialTagsOptions;
