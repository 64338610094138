/* Common Imports */

import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useRouter } from "next/router";
/* Component Imports */

import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    trackColor: "red",
    selectionColor: "red",
    padding: "0px 0px 15px 0px",
  },
  contentHead: {
    width: "100%",
  },
  minMaxHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "0px 0px 5px 0px",
  },
  textFieldStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
    padding: "0px 0rem 0px 0px",
    [theme.breakpoints.only("xs")]: {
      padding: "0px 1rem 0px 0px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px 1rem 0px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0px 0rem 0px 0px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 2rem 0px 0px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0px 14rem 0px 0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "Black",
    },
  },
}));

const CustomSlider = withStyles({
  root: {
    color: "red",
    height: 25,
    padding: "0px 0px 0px 0px",
    top: 10,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -6,
    marginLeft: -8,
    boxShadow: "#EBEBEB 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      backgroundColor: "currentColor",
      marginLeft: 6,
      marginRight: 5,
    },
    color: "red",
  },
  active: {},
  track: {
    height: 8,
  },
  mark: {
    backgroundColor: "#FFF",
  },
  rail: {
    color: "#FFF",
    opacity: 6,
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
})(Slider);

const marks = [
  {
    label: "G",
    value: 0,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "50+",
    value: 50,
  },
];

const SliderBHK = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [min, setMin] = React.useState(0);
  const [max, setMax] = React.useState(50);

  const handleChangeSlider = (event, value) => {
    setMin(value[0]);
    setMax(value[1]);
  };
  const handleChangeMainTextFieldMin = (event) => {
    setMin(
      event.target.value === ""
        ? Number(event.target.value)
        : Number(event.target.value)
    );
  };
  const handleChangeMainTextFieldMax = (event) => {
    setMax(
      event.target.value === ""
        ? Number(event.target.value)
        : Number(event.target.value)
    );
  };
  React.useEffect(() => {
    typeof props.fs === "function" ? props.fs(min, max) : undefined;
  }, [min, max]);

  React.useEffect(() => {
    if (props.reset === true) {
      setMin(0);
      setMax(50);
    }
  }, [props.reset]);

  React.useEffect(() => {
    setMin(router.query.floorsMin ? parseInt(router.query.floorsMin) : 0);
    setMax(router.query.floorsMax ? parseInt(router.query.floorsMax) : 50);
  }, [router.query]);

  return (
    <React.Fragment>
      <div className={classes.contentHead}>
        <div className={classes.minMaxHead}>
          <div>
            <Typography>Min</Typography>
          </div>
          <div>
            <Typography>Max</Typography>
          </div>
        </div>
        <div className={classes.root}>
          <CustomSlider
            onTouchEnd={props.oc}
            onClick={props.oc}
            marks={marks}
            step={1}
            min={0}
            max={50}
            value={[min, max]}
            onChange={handleChangeSlider}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
        </div>
        <div className={classes.textFieldStyle}>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMin}
              value={min}
              placeholder="Minimum"
            />
          </div>
          <div>
            <TextField
              onClick={props.oc}
              onChange={handleChangeMainTextFieldMax}
              value={max}
              placeholder="Maximum"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SliderBHK;
