/* Common Imports */
import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Button,
  Chip,
  Slide,
  Fade,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./selectedFilterStyle";
import { useRouter } from "next/router";

const SelectedFilter = (props) => {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState(props.PTSelected);
  const [chipDataST, setChipDataST] = React.useState(props.STSelected);
  const [chipDataFC, setChipDataFC] = React.useState(props.FCSelected);
  const [nm, setNm] = React.useState("");
  const [reset] = React.useState([]);
  const router = useRouter();
  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    return () => {
      setState({});
    };
  }, [state]);
  const handleDelete = (DeleteThisShit) => {
    setNm(DeleteThisShit);
    setChipData((chipData) =>
      chipData.filter((chip) => chip.text !== DeleteThisShit)
    );
    setChipDataST((chipDataST) =>
      chipDataST.filter((gg) => gg.text !== DeleteThisShit)
    );
    setChipDataFC((chipDataFC) =>
      chipDataFC.filter((FC) => FC.text !== DeleteThisShit)
    );
  };
  React.useEffect(() => {
    setChipData(props.PTSelected);
    setChipDataST(props.STSelected);
    setChipDataFC(props.FCSelected);
  }, [props.PTSelected, props.STSelected, props.FCSelected]);

  React.useEffect(() => {
    typeof props.chip === "function" ? props.chip(chipData, nm) : undefined;
    typeof props.chipFC === "function"
      ? props.chipFC(chipDataFC, nm)
      : undefined;
    typeof props.chipST === "function"
      ? props.chipST(chipDataST, nm)
      : undefined;
  }, [nm, chipData, chipDataFC, chipDataST]);

  React.useEffect(() => {
    setTimeout(() => {
      !props.reset ? setExpanded(true) : undefined;
    }, 250);
  }, [props.reset]);

  return (
    <React.Fragment>
      <Paper>
        <Accordion expanded={expanded}>
          <Slide direction="down" in={!props.reset} mountOnEnter unmountOnExit>
            <Fade in={!props.reset} timeout={{ enter: 500 }}>
              <AccordionSummary
                onClick={() => {
                  setExpanded(!expanded);
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.bg}
              >
                <Typography
                  component="span"
                  variant="h6"
                  className={classes.sectionTitle}
                >
                  Selected Filters
                  <Paper className={classes.gg}>
                    {props.STSelected.length +
                      props.PTSelected.length +
                      props.FCSelected.length +
                      (props.valueMin != 0 && props.valueMax != 0 ? 1 : 0) +
                      (props.bhkMin != 0 &&
                      props.bhkMax != 0 &&
                      props.bhkShow == false
                        ? 1
                        : 0) +
                      (props.bathMin != 0 &&
                      props.bathMax != 0 &&
                      props.bathroomShow == false
                        ? 1
                        : 0) +
                      (props.floorMin != 0 ||
                      (props.floorMax != 0 && props.floorShow == false)
                        ? 1
                        : 0) +
                      (props.areaMin != 0 && props.areaMax != 0 ? 1 : 0) +
                      "/" +
                      "25"}
                    Filters Selected
                  </Paper>
                </Typography>
              </AccordionSummary>
            </Fade>
          </Slide>
          <AccordionDetails>
            {!props.reset ? (
              <div className={classes.container}>
                <div className={classes.flex}>
                  <Paper
                    className={
                      (props.valueMin == 0 && props.valueMax == 0) ||
                      props.reset === true
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      Budget:{" "}
                      {props.valueMin < 100
                        ? props.valueMin
                        : props.valueMin / 100}{" "}
                      {props.budgetUnitMin} -{" "}
                      {props.valueMax < 100
                        ? props.valueMax
                        : props.valueMax / 100}{" "}
                      {props.budgetUnitMax}
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      (props.bhkMin == 0 && props.bhkMax == 0) ||
                      props.reset === true ||
                      (props.show == true && props.bhkShow == true)
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      Bedroom: {props.bhkMin}BHK - {props.bhkMax}BHK
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      props.PTSelected.length === 0
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      <span className={classes.td}>Property Type:</span>{" "}
                      <Typography component="span" className={classes.chipSt}>
                        {chipData.map((data, key) => {
                          return (
                            <Chip
                              key={key}
                              label={data.text}
                              onDelete={(event) => {
                                handleDelete(data.text);
                              }}
                            />
                          );
                        })}
                      </Typography>
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      (props.floorMin == 0 && props.floorMax == 0) ||
                      props.reset ||
                      (props.show == true && props.floorShow == true)
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      Floors: {props.floorMin} - {props.floorMax} Floors
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      (props.areaMin == 0 && props.areaMax == 0) ||
                      props.reset === true
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      Area: {props.areaMin} - {props.areaMax} {props.areaUnit}
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      (props.bathMin == 0 && props.bathMax == 0) ||
                      props.reset === true ||
                      (props.show == true && props.bathroomShow == true)
                        ? classes.rootNo
                        : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      Bathrooms: {props.bathMin} - {props.bathMax}
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      props.STSelected == 0 ? classes.rootNo : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      <span className={classes.td}>Special Tags:</span>{" "}
                      <Typography component="span" className={classes.chipSt}>
                        {chipDataST.map((data, key) => {
                          return (
                            <Chip
                              key={key}
                              label={data.text.substring(data.text[(0, 2)])}
                              className={classes.chip}
                              onDelete={() => {
                                handleDelete(data.text);
                              }}
                            />
                          );
                        })}
                      </Typography>
                    </Typography>
                  </Paper>
                  <Paper
                    className={
                      props.FCSelected == 0 ? classes.rootNo : classes.root
                    }
                  >
                    <Typography component="span" className={classes.textHead}>
                      <span className={classes.td1}>Facing:</span>
                      <Typography component="span" className={classes.chipSt}>
                        {chipDataFC.map((data, key) => {
                          return (
                            <Chip
                              key={key}
                              label={data.text}
                              className={classes.chip}
                              onDelete={() => {
                                handleDelete(data.text);
                              }}
                            />
                          );
                        })}
                      </Typography>
                    </Typography>
                  </Paper>
                </div>
                <div className={classes.customContainer}>
                  <Button
                    rel="noopener"
                    className={classes.primaryButton}
                    onClick={(event) => {
                      typeof props.clearFilter === "function"
                        ? props.clearFilter(reset)
                        : undefined;
                      window.history.replaceState("", "", "/properties");
                      router.reload();
                      typeof props.ocClick === "function"
                        ? props.ocClick(event)
                        : undefined;
                    }}
                  >
                    Clear Filter
                  </Button>
                  <Button
                    rel="noopener"
                    className={classes.secondaryButton}
                    onClick={(event) => {
                      window.history.replaceState("", "", "/properties");
                      location.search +=
                        "&c=" +
                        props.place +
                        "&t=" +
                        props.res +
                        "&q=" +
                        props.search +
                        (props.valueMin == 0 || reset === true
                          ? ""
                          : "&valueMin=" +
                            (props.valueMin >= 100
                              ? props.valueMin / 100
                              : props.valueMin)) +
                        (props.valueMax == 0
                          ? ""
                          : "&valueMax=" +
                            (props.valueMax > 100
                              ? props.valueMax / 100
                              : props.valueMax)) +
                        (props.budgetUnitMin == 0
                          ? ""
                          : "&budgetUnitMin=" + props.budgetUnitMin) +
                        (props.budgetUnitMax == 0
                          ? ""
                          : "&budgetUnitMax=" + props.budgetUnitMax) +
                        (props.bhkMin == 0 || props.bhkShow == true
                          ? ""
                          : "&bhkMin=" + props.bhkMin) +
                        (props.bhkMax == 0 || props.bhkShow == true
                          ? ""
                          : "&bhkMax=" + props.bhkMax) +
                        (props.PTSelected == 0
                          ? ""
                          : "&property=" +
                            props.PTSelected.map((s, key) => {
                              return s.name;
                            })) +
                        (props.floorShow == true
                          ? ""
                          : "&floorsMin=" + props.floorMin) +
                        (props.floorMax == 0 || props.floorShow == true
                          ? ""
                          : "&floorsMax=" + props.floorMax) +
                        (props.areaMin === 0
                          ? ""
                          : "&areaMin=" + props.areaMin) +
                        (props.areaMax === 0
                          ? ""
                          : "&areaMax=" + props.areaMax) +
                        (props.areaUnit == 0
                          ? ""
                          : "&areaUnit=" + props.areaUnit) +
                        (props.bathMin == 0 || props.bathroomShow == true
                          ? ""
                          : "&bathroomMin=" + props.bathMin) +
                        (props.bathMax == 0 || props.bathroomShow == true
                          ? ""
                          : "&bathroomMax=" + props.bathMax) +
                        (props.STSelected == 0
                          ? ""
                          : "&specialtags=" +
                            props.STSelected.map((s, key) => {
                              return s.name;
                            })) +
                        (props.FCSelected == 0
                          ? ""
                          : "&facing=" +
                            props.FCSelected.map((s, key) => {
                              return s.name;
                            }));
                      typeof props.apClick === "function"
                        ? props.apClick(event)
                        : undefined;
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </React.Fragment>
  );
};

export default SelectedFilter;
