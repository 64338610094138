import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  bg: {
    background: "#FAFAFA",
  },
  textHead: {
    margin: "0px 0px 0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "2rem",
    width: "auto",
  },
  root: {
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "flex-start",
    // alignItems: "center",
    // flexWrap: "wrap",
    // margin: 0,
    // overflow: "scroll",
    // whiteSpace: "nowrap",
    // textOverflow: "ellipsis",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
    // borderRadius: "0.25rem",
    // margin: "0px 0px 0px 5px",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "18rem",
      height: "2.446rem",
      margin: "0px 0px 0px 10px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      width: "24.7rem",
      height: "2.446rem",
      margin: "0px 0px 0px 13px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "21rem",
      height: "2.446rem",
      margin: "0px 0px 0px 10px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 80)]: {
      width: "22rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 252)]: {
      width: "25rem",
      height: "2.446rem",
      margin: "0px 0px 0px 18px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 320)]: {
      width: "25rem",
      height: "2.446rem",
      margin: "0px 0px 0px 35px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "19.5rem",
      height: "2.446rem",
    },
  },
  td: {
    width: "12rem",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "18rem",
    },
    fontSize: "0.89rem",
  },
  td1: {
    width: "8rem",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "8rem",
    },
  },
  chipSt: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    // flexWrap: "wrap",
    margin: 0,
    overflow: "scroll",
    // whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    borderRadius: "0.25rem",
    margin: "0px 0px 0px 5px",
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "100%",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "18rem",
      height: "2.446rem",
      margin: "0px 0px 0px 10px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
      width: "24.7rem",
      height: "2.446rem",
      margin: "0px 0px 0px 13px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "21rem",
      height: "2.446rem",
      margin: "0px 0px 0px 10px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 80)]: {
      width: "22rem",
      height: "2.446rem",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 252)]: {
      width: "25rem",
      height: "2.446rem",
      margin: "0px 0px 0px 18px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.lg + 320)]: {
      width: "25rem",
      height: "2.446rem",
      margin: "0px 0px 0px 35px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "19.5rem",
      height: "2.446rem",
    },
  },
  rootNo: {
    display: "none",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-star",
    alignItems: "left",
    width: "100%",
  },
  sectionTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    padding: "0px 0px 0px 10px",
  },
  gg: {
    margin: "0px 0px 0px 10px",
    fontSize: "1rem",
    height: "1.625rem",
    display: "flex",
    fontWeight: 400,
    width: "8.813rem",
    fontSize: "0.75rem",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    border: "double 2px transparent",
    backgroundImage:
      "linear-gradient(white, white), radial-gradient(circle at top left, #F74134, #EB1C61)",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
  nogg: {
    display: "none",
  },
  customContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    margin: "20px 0px 0px 0px",
  },
  primaryButton: {
    margin: "0rem 0rem 0rem 1rem",
    [theme.breakpoints.down("xs")]: {
      width: "7rem",
    },
    borderRadius: "4px",
    border: "3px solid transparent",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
  secondaryButton: {
    margin: "0rem 0rem 0rem 1rem",
    width: "6rem",
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      width: "4rem",
    },
    borderRadius: "4px",
    border: "3px solid transparent",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
  },
}));
export default useStyles;
