/* Common Imports */
import React from "react";
import SliderFloor from "./sub-components/SliderFloor";
// import { useRouter } from "next/router";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  budgetHead: {
    width: "100%",
  },
  sectionHead: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "2.25rem",
    padding: "0px 0px 0px 10px",
  },
  budgetSummary: {
    backgroundColor: "#fafafa",
  },
}));

const FloorsMain = (props) => {
  const classes = useStyles();
  const prt = props.pt;
  const i = 0;
  return prt != 0 ? (
    prt[i].text === "Apartment" || prt[i].text === "House" ? (
      <div className={classes.customContainer}>
        <Accordion className={classes.budgetHead}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.budgetSummary}
          >
            <Typography className={classes.sectionHead}>Floors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SliderFloor oc={props.oc} fs={props.fs} reset={props.reset} />
          </AccordionDetails>
        </Accordion>
      </div>
    ) : null
  ) : null;
};

export default FloorsMain;
