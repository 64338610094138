/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import PropertyTypeMain from "./PropertyType/PropertyTypeMain";
import SpecialTagsMain from './SpecialTags/SpecialTagsMain';

// import FloorsMain from "./Floors/FloorsMain";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
    margin: "1rem 0rem 1rem 0rem",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
}));

const AdvSrhST = (props) => {
  const classes = useStyles();
  // const [floorsMin] = React.useState(0);
  // const [floorsMax] = React.useState(0);
  const [STSelected, setSTSelected] = React.useState([]);
  const [CBSelected, setCBSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateCB === "function"
      ? props.updateCB(CBSelected)
      : undefined;
  }, [CBSelected]);

   React.useEffect(() => {
     typeof props.updateST === 'function'
       ? props.updateST(STSelected)
       : undefined;
   }, [STSelected]);

  // React.useEffect(() => {
  //     typeof props.floorsFs === "function"
  //       ? props.floorsFs(floorsMin, floorsMax)
  //       : undefined;
  //   }, [floorsMin, floorsMax]);
  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <PropertyTypeMain
          oc2={props.oc2}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateCB={(sel) => {
            setCBSelected(sel);
          }}
        />
        <SpecialTagsMain
          oc={props.oc}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateST={(sel) => {
            setSTSelected(sel);
          }}
        />
        {/* <FloorsMain
          oc={props.oc}
          reset={props.reset}
          fs={props.floorsFs}
          pt={props.PTSelected}
        /> */}
      </div>
    </React.Fragment>
  );
};

export default AdvSrhST;
