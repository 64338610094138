/* Common Imports */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

/* Component Imports */

import BudgetMain from './Budget/BudgetMain';
import AreaMain from './Area/AreaMain';
// import BHKMain from "./BHK/BHKMain";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '1rem',
    margin: '1rem 0rem 1rem 0rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
}));

const AdvSrh = (props) => {
  const classes = useStyles();

  const [budgetMin] = React.useState(0);
  const [budgetMax] = React.useState(0);
  const [areaMin] = React.useState(0);
  const [areaMax] = React.useState(0);
  const [areaUnit] = React.useState('');
  const [bUnitMin] = React.useState('');
  const [bUnitMax] = React.useState('');

  React.useEffect(() => {
    typeof props.budgetSu === 'function'
      ? props.budgetSu(budgetMin, budgetMax, bUnitMin, bUnitMax)
      : undefined;
  }, [budgetMin, budgetMax, bUnitMin, bUnitMax]);

  React.useEffect(() => {
    typeof props.areaAu === 'function'
      ? props.areaAu(areaMin, areaMax, areaUnit)
      : undefined;
  }, [areaMin, areaMax, areaUnit]);

  return (
    <React.Fragment>
      <div className={classes.sectionContainer}>
        <BudgetMain oc={props.oc1} reset={props.reset} cu={props.budgetSu} />
        <AreaMain oc1={props.oc} reset1={props.reset} au={props.areaAu} />
      </div>
    </React.Fragment>
  );
};

export default AdvSrh;
