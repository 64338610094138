/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Option from "./sub-components/botp";

const useStyles = makeStyles((theme) => ({
  stTitleBundler: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));

const PropertyTypeMain = (props) => {
  const classes = useStyles();
  const [CBSelected, setCBSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateCB === "function"
      ? props.updateCB(CBSelected)
      : undefined;
  }, [CBSelected]);

  return (
    <React.Fragment>
      <div className={classes.stTitleBundler}>
        <Option
          oc={props.oc2}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateCB={(sel) => {
            setCBSelected(sel);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default PropertyTypeMain;
