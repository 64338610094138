/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import SpecialTagsOptions from "./sub-components/SpecialTagsOptions";

const useStyles = makeStyles((theme) => ({
  stTitleBundler: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
}));
const SpecialTagsMain = (props) => {
  const classes = useStyles();
  const [STSelected, setSTSelected] = React.useState([]);

  React.useEffect(() => {
    typeof props.updateST === "function"
      ? props.updateST(STSelected)
      : undefined;
  }, [STSelected]);

  return (
    <React.Fragment>
      <div className={classes.stTitleBundler}>
        <SpecialTagsOptions
          oc={props.oc}
          reset={props.reset}
          chip={props.chip}
          nm={props.nm}
          updateST={(sel) => {
            setSTSelected(sel);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default SpecialTagsMain;
