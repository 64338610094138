const options = [
    {
      icon: "/images/icons/property/nri.svg",
      text: "NRI",
      name: "nri",
    },
    {
      icon: "/images/icons/property/featured.svg",
      text: "Featured",
      name: "featured",
    },
    {
      icon: "/images/icons/search/five_star.svg",
      text: "Top Five",
      name: "top_five",
    },
    {
      icon: "/images/icons/search/growth.svg",
      text: "Growth",
      name: "growth",
    },
    {
      icon: "/images/icons/property/new.svg",
      text: "New",
      name: "new",
    },
  ];
  export default options;
