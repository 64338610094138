import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Paper, Select, MenuItem, InputBase } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useRouter } from "next/router";
const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    background: "#fffff",
    "&:focus": {
      background: "transparent",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  bscContainer: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "9rem",
    borderRadius: "4px 0px 0px 4px",
    margin: "0px 0px 0px 0px",
    [theme.breakpoints.down("xs")]: {
      width: "6.5rem",
    },
    [theme.breakpoints.down(theme.breakpoints.values.md + 70)]: {
      margin: "10px 0px 0px 0px",
    },
  },
  paperContainer: {
    width: "100%",
    padding: "0px 0px 0px 10px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 0px 0px",
    },
    [theme.breakpoints.down(theme.breakpoints.values.xs + 45)]: {
      fontSize: "10px",
      width: "6rem",
      padding: "5px 0px 0px 0px",
    },
  },
  sizeContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      width: "100%",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xs + 259.95)]: {
      width: "9rem",
      margin: "0px 10px 0px 25px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm + 168)]: {
      width: "9rem",
      margin: "0px 10px 10px 0px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm + 200)]: {
      width: "9rem",
      margin: "0px 10px 0px 0px",
    },
  },
}));
const AreaDropDown = (props) => {
  const classes = useStyles();
  const router = useRouter();
  const [place, setPlace] = React.useState("any");
  const handleChange = (event) => {
    setPlace(event.target.value);
  };
  React.useEffect(() => {
    typeof props.place === "function"
      ? props.place(place)
      : setPlace(props.place);
  }, [place]);
  React.useEffect(() => {
    setPlace(router.query.c ? router.query.c : "any");
  }, [router.query]);
  return (
    <div className={classes.sizeContainer}>
      <Paper elevation={0} className={classes.bscContainer}>
        <Select
          value={place}
          variant="outlined"
          label="Age"
          onChange={handleChange}
          input={<BootstrapInput />}
          IconComponent={ExpandMoreIcon}
          className={classes.paperContainer}
          defaultValue={place}
        >
          <MenuItem value="any">Any City</MenuItem>
          <MenuItem value="bengaluru">Bengaluru</MenuItem>
          <MenuItem value="mysore">Mysore</MenuItem>
        </Select>
      </Paper>
    </div>
  );
};
export default AreaDropDown;
